class Modal {
	static create () {
		const instance = new this(...arguments)
		return instance
	}

	static createAndOpen (data) {
		const modal = this.create(...arguments)
		modal.data = data
		modal.open()
		return modal
	}

	component
	isBig = false

	constructor () {
		this.app = window.app
	}

	open () {
		this.app.openModal(this)
	}

	close () {
		this.app.closeModal()
	}
}

export { Modal }
