<template>
	<button class="card" @click="handleOpenModal" v-if="realization.published" v-animate="`opacity`">
		<cms-image
			class="card-img"
			lazy
			:src="realization.thumbnail"
			:base="{width: 414, height: 200}"
            :md="{width: 768, height: 200}"
			:lg="{width: 930, height: 544}"
			position="center"
		/>
		<base-font class="card-hover" variant="small-heading" color="white" weight="semiBold">
			{{langs.hover_text}}
		</base-font>
		<base-font class="card-title" variant="small-heading" marginReset color="white">
			{{ realization.name[$app.language] }}
		</base-font>
	</button>
</template>
<script>
import { PortfolioModal } from '~/website/front/components/organisms/PortfolioModal'
export default {
	props: {
		realization: Object,
		card: Object
	},
	methods: {
		handleOpenModal () {
			PortfolioModal.createAndOpen(this.realization)
		}
	},
	computed: {
		langs () {
			return this.$app.translator.get('realizations')
		}
	}
}
</script>
<style lang="scss" scoped>
.card {
	width: 100%;
	height: 100%;
	position: relative;
	border: none;
	outline: none;
	background: none;
	padding: 0;
	cursor: pointer;

	::v-deep .base-image {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
	::v-deep &-title {
		position: absolute;
		bottom: 2rem;
		left: 2rem;
		z-index: 2;
		text-align: left;
	}
	::v-deep &-hover {
		display: none;
	}
	@include media-breakpoint-up(xl) {
        ::v-deep &-hover {
        display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-80%,-50%);
		z-index: 3;
		opacity: 0;
		transition: .3s ease-in;
	}
		&:hover {
			::v-deep .card-hover {
				transform: translate(-50%,-50%);
				opacity: 1;
			}
			&::after {
				width: 100%;
			}
		}
	}
	&::after, &::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}
	&::before {
		width: 100%;
		background: rgba($black, .5);
	}
	&::after {
		background: rgba($secondary, .83);
		width: 6px;
		transition: .3s ease-in;
		@include media-breakpoint-up(xl) {
			width: 9px;
		}
	}

}
</style>
