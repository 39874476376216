<template>
	<div class="section-wrapper">
		<base-heading heading="realizations_page.title" subheading="realizations_page.subtitle" tag="h1"/>
		<base-category single-category :categoryData="realizationCategories" :handler="handleInput" v-model="selectedTags"/>
		<base-container>
			<div class="realizations-grid">
				<portfolio-card
					v-for="realization in realizations"
					:key="realization.id"
					:realization="realization"
				/>
			</div>
		</base-container>
	</div>
</template>
<script>
import PortfolioCard from '~/website/front/components/molecules/cards/PortfolioCard/PortfolioCard.vue'
import BaseHeading from '~/website/front/components/molecules/headings/BaseHeading/BaseHeading.vue'
import BaseCategory from '~/website/front/components/molecules/BaseCategory/BaseCategory'
import { RealizationCategories } from 'utils/enums/RealizationCategories'
import { pageMixin } from '~/website/front/core/pages/page.mixin'

export default {
	components: {
		BaseHeading,
		PortfolioCard,
		BaseCategory
	},
	mixins: [pageMixin],
	computed: {
		realizations () {
			return this.$app.page.realizations
		},
		realizationCategories () {
			return RealizationCategories
		}
	},
	data: () => ({
		selectedTags: {
			categories: []
		}
	}),
	methods: {
		async filter () {
			const query = {}
			if (this.selectedTags.categories.includes('highlight')) query.isHighlighted = true
			else if (this.selectedTags.categories.length) query.category = this.selectedTags.categories
			await this.$app.page.fetchRealizations(query)
		},
		async handleInput (tag, element) {
			if (!this.selectedTags[tag]) {
				this.selectedTags[tag] = [element]
			} else if (this.selectedTags[tag].includes(element)) {
				this.selectedTags[tag] = []
			} else if (!this.selectedTags[tag].includes(element)) {
				this.selectedTags[tag] = [element]
			}
			await this.filter()
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	padding: 10rem 0 4rem 0;
	@include media-breakpoint-up(xl) {
		padding: 16rem 0 4rem 0;
	}

	.realizations-grid {
		display: grid;
		gap: 2rem;
		margin-top: 5rem;
        grid-auto-rows: 18rem;

		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(3, 1fr);
			grid-auto-flow: dense;

			.card:first-child {
				grid-column: span 2;
				grid-row: span 2;
			}
			.card:nth-of-type(4n + 5),
			.card:nth-of-type(4n + 6) {
				grid-column: span 2;
			}
		}
		@include media-breakpoint-up(xl) {
			gap: 3rem;
            grid-auto-rows: 26rem;
		}
	}
}
</style>
