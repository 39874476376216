<template>
	<div class="wrapper">
		<ul class="modal-list">
			<li class="list-item" v-for="item in realizationAttributes" :key="item.key">
				<base-font v-if="item.attributeName" color="secondary" uppercase weight="bold" tag="span">{{item.attributeName}}:</base-font>
				<ul class="modal-list" v-if="splitAttributeDescription(item.attributeDescription)">
					<base-font v-for="(element, id) of splitAttributeDescription(item.attributeDescription)" :key="id" tag="li">
						{{ element }}
					</base-font>
				</ul>
				<base-font v-else tag="span">{{item.attributeDescription}}</base-font>
			</li>
		</ul>
		<div class="gallery">
			<div class="thumbnails">
				<figure
					class="thumbnail"
					:class="{active: i === index}"
					v-for="(image, i) in realizationGallery"
					:key="image.image.path"
					@click="goTo(i)"
				>
					<cms-image
						class="gallery-img"
						:src="image.image"
						:base="{height: 45, width: 45}"
						:md="{height: 45, width: 60}"
					/>
				</figure>
			</div>
			<cms-image
				class="gallery-img img-show"
				:src="activeImageSrc.image"
				:base="{height: 330, width: 330}"
				:sm="{height: 450}"
				:md="{height: 450}"
				:lg="{height: 450, width: 450}"
				:xxl="{height: 443, width: 600}"
				position="center"
			/>
		</div>
	</div>
</template>
<script>
import CmsImage from '~/cms/front/components/CmsImage/CmsImage.vue'
import BaseFont from '../../atoms/BaseFont/BaseFont.vue'
export default {
	components: { CmsImage, BaseFont },
	data () {
		return {
			formattedData: undefined,
			title: '',
			gallery: undefined,
			index: 0
		}
	},
	computed: {
		modal () {
			return this.$app.modal.data
		},
		translate () {
			return this.$app.translator.get('realizations')
		},
		activeImageSrc () {
			return this.realizationGallery[this.index]
		},
		realizationAttributes () {
			return this.modal.data[this.$app.language].info
		},
		realizationGallery () {
			return this.modal.data[this.$app.language].gallery
		}
	},
	methods: {
		nextImage () {
			this.goTo(this.index + 1)
		},
		prevImage () {
			this.goTo(this.index - 1)
		},
		goTo (index) {
			this.index = (index + this.realizationGallery.length) % this.realizationGallery.length
		},
		splitAttributeDescription (attributeDescription) {
			if (attributeDescription.includes('\n')) return attributeDescription.split('\n')
			if (attributeDescription.includes('\r')) return attributeDescription.split('\r')
		}
	}
}
</script>
<style lang="scss" scoped>
.wrapper {
	display: flex;
	flex-direction: column;
	padding-block: 1rem;
	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template-columns: repeat(2,1fr);
		gap: 2rem;
	}
	@include media-breakpoint-up(xxl) {
		gap: 5rem;
	}
	.gallery {
		display: flex;
		flex-direction: column;
		gap: 5px;
		@include media-breakpoint-up(xl) {
			flex-direction: row;
			width: auto;
		}
		.thumbnails {
			display: flex;
			flex-direction: row;
			gap: 5px;
			overflow: auto;
			width: 100%;
			@include media-breakpoint-up(xl) {
				overflow: auto;
				flex-direction: column;
				width: auto;
			}
		}

		.img-show {
			border: 1px solid $gray-300;
			& ::v-deep .base-image {
				width: 100%;
				height: 100%;
				@include media-breakpoint-up(xl) {
					width: auto;
					max-height: 550px;
				}
			}
		}
		.thumbnail {
			position: relative;
			cursor: pointer;
			margin: 0;
			height: 45px;
			overflow: hidden;
			flex-shrink: 0;

			@include media-breakpoint-up(md) {
				&::before {
					position: absolute;
					content: '';
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba($black, 0.35);
					opacity: 0;
					@include transition;
				}
				&.active {
					&::before {
						opacity: 1;
					}
				}
			}
			@include media-breakpoint-up(xl) {
				max-width: 60px;
				&:hover {
					&::before {
						opacity: 1;
					}
				}
			}
		}
	}
	.modal-list {
		padding: 0;
		list-style-type: none;
		margin: 0;

		.list-item {
			display: grid;
			padding-bottom: 1rem;
			gap: 2rem;
			grid-template-columns: 130px 1fr;
			@include media-breakpoint-up(xl) {
				grid-template-columns: 170px 1fr;
			}

			&:not(:last-of-type) {
				border-bottom: 1px solid $gray-300;
			}
			&:not(:first-of-type) {
				padding-top: 1rem
			}
		}
	}
}
</style>
