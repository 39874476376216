import { Modal } from '@f/core/Modal'
import PortfolioModalComponent from './PortfolioModal.vue'

class PortfolioModal extends Modal {
	component = PortfolioModalComponent
}

export {
	PortfolioModal
}
