import { Page } from '~/cms/front/core/pages/Page'
import component from './RealizationsPage.vue'

class RealizationsPage extends Page {
	static alias = 'realizations'

	async init () {
		await super.init(...arguments)
		await this.fetchRealizations()
		await this.app.translator.prefetch('realizations')
	}

	component = component
	realizations = []

	async fetchRealizations (data) {
		const Realization = this.app.getEntity('realization')
		const { realizations } = await Realization.fetch(data)
		this.realizations = realizations
	}
}

export { RealizationsPage }
